#widget-container {
    justify-content: space-evenly;
    align-items: center;
    font-family: 'helvetica', sans-serif;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    column-gap: 10px;
    row-gap: 5px;
}

.widget {
    display: flex;
    position: relative;
    height: 30vh;
    justify-content: center;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 20px;
    flex-direction: column;
    flex-grow: 1;

}

#widget3 {
    min-height: 450px;
    flex-grow: 2;
}

#widget4 {
    height: 100px;
    flex-grow: 2;
}
