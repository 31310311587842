
#speedbox-info {
    text-align: right;
    color: black;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

#speedbox-info div {
    align-content: center;
}
