#speedbox-container {
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.left {
    color:  #303030;
}

.none {
    color: red;
}

.right {
    color: darkblue;

}


#speedbox-data {

    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#speedbox-status {
    position: fixed;
    top: 0;
    left: 0;
    height: 5px;
    width: 5px;
}

.speedbox-connected {
}

.speedbox-disconnected {
    background-color: red;
}

#speedbox-value {
    font-size: 10vh;
}

#speedbox-debug {
    position: fixed;
    top: 0;
    left: 0;
}
